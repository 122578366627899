



































































































































































































































































































import { defineComponent, ref, computed, Ref, watch } from '@vue/composition-api';
import core from '@/admin/core';
import { useGifts, getExpirationTypeText, getExpirationPeriodIntervalText } from '@/admin/gift';
import { addGift, GiftHistoryProps } from '@/admin/history';
import { useNotification } from '@/composition/notification';
import { useConfirm } from '@/composition/confirm';
import { downloadCsv } from '@/admin/util';
import FcNumberForm from '@/components/FcNumberForm.vue';
import { useUser } from '@/admin/user';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcRoleDeny from '@/components/FcRoleDeny.vue';
import { DisplayDate } from '@/admin/util';

export default defineComponent({
  name: 'Gifts',
  components: {
    FcNumberForm,
    FcRoleLoading,
    FcRoleDeny,
  },
  setup() {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('gifts'));

    const isPermitted = core.isPermitted; // ユーザー情報取得用 IP制限の確認
    const { gifts } = useGifts();
    const { getAllUsers } = useUser();
    const notification = useNotification();
    const { confirmDialog } = useConfirm();
    const headers = [
      { text: 'ステータス', value: 'isArchive' },
      { text: '作成日', value: 'createDate.date' },
      { text: 'ID/タイトル', value: 'title', width: '300px' },
      { text: '有効期限', value: 'expiration' },
      { text: 'もぎり機能', value: 'isUsable' },
      { text: '', value: 'actions', sortable: false, align: 'end' },
    ];

    // フリーワード検索
    const searchWord = ref('');
    const isDisplayItem = ref(false);
    const displayGifts = computed(() => {
      const filterGift = gifts.items
        // 削除スイッチによる絞り込み
        .filter((item) => (!isDisplayItem.value ? !item.isArchive : item))
        // フリーワードによる絞り込み
        .filter((item) => !searchWord.value || JSON.stringify(item).includes(searchWord.value));
      return filterGift;
    });

    const deleteGift = async (giftId: string) => {
      if (!(await confirmDialog('本当に削除しますか？'))) return;
      try {
        await gifts.deleteGift(giftId);
        gifts.items = gifts.items.filter((item) => item.giftId !== giftId);
        notification.notify('削除しました。');
      } catch (error) {
        notification.error(error);
      }
    };

    const active = ref(false);
    const isCheckUsers = ref(false);
    const isAddGiftDone = ref(false);
    const userId = ref('');
    const userIds: Ref<string[]> = ref([]);
    const giftId = ref('');
    const failedUserIds: Ref<string[]> = ref([]);
    const open = (id: string) => {
      giftId.value = id;
      active.value = true;
    };
    const items: Ref<{
      userId: string;
      quantity: number;
      status: string;
    }[]> = ref([]);
    const isErrorUser = ref(true);
    const dialogTableHeaders: Ref<{
      text: string;
      value: string;
      align?: string;
    }[]> = ref([{ text: '', value: 'actions', align: 'end' }]);
    const userCsv: Ref<Blob | null> = ref(null);
    const setCsv = (file?: Blob) => {
      userCsv.value = file || null;
    };
    const upload = () => {
      if (items.value.length) return;
      if (!userCsv.value) return alert('ファイルを選択してください。');
      const reader = new FileReader();
      reader.readAsText(userCsv.value);
      reader.onload = () => {
        if (!reader.result) return;
        const csv = reader.result.toString().replace(/\r\n/g, '\n');
        const rows = csv.split('\n');
        const headerRow = rows.shift() || '';
        const headerColumns = headerRow.split(',');
        const headers = headerColumns.map((headerColumn) => ({
          text: headerColumn,
          value: headerColumn,
        }));
        dialogTableHeaders.value.unshift(...headers);
        items.value =
          // userid・quantityともに重複していたら削除する
          [...new Set(rows)].map((row) => {
            const data: { [key: string]: any }[number] = {};
            const columns = row.split(',');
            columns.forEach((_, index) => {
              data[headerColumns[index]] = columns[index];
              data['status'] = 'unchecked';
            });
            return data;
          });
      };
    };

    // ダウンロードボタン
    const download = () => {
      // actionsの列は削除
      const csvHeaders = [
        { text: 'userId', value: 'userId' },
        { text: 'quantity', value: 'quantity' },
      ];
      downloadCsv(csvHeaders, [], 'gift-users.csv');
    };

    const add = async () => {
      if (!(await confirmDialog('本当に特典を追加しますか？'))) return;
      for (let index = 0; index < items.value.length; index++) {
        const item = items.value[index];
        if (!item.quantity) {
          alert(`${item.userId}の数量を確認してください。`);
          return;
        }
      }
      items.value.forEach(async (item) => {
        const props: GiftHistoryProps = {
          endUsername: item.userId,
          historyType: 'gift',
          giftId: giftId.value,
          quantity: item.quantity,
        };
        try {
          await addGift(props);
        } catch (error) {
          failedUserIds.value.push(item.userId);
          failedUserIds.value = [...new Set([...failedUserIds.value])];
          notification.error(error);
        }
      });
      notification.notify('追加処理が完了しました。');
      if (!failedUserIds.value.length) {
        active.value = false;
      }
      isAddGiftDone.value = true;
    };

    const close = async () => {
      if (
        failedUserIds.value.length &&
        !(await confirmDialog('特典を付与できなかったユーザーには特典は付与されません。\nダイアログを閉じますか？'))
      )
        return;
      else {
        items.value = [];
        active.value = false;
        failedUserIds.value = [];
        isAddGiftDone.value = false;
        dialogTableHeaders.value = [{ text: '', value: 'actions', align: 'end' }];
      }
    };

    const deleteUpdateGift = async (item: any) => {
      if (!(await confirmDialog('本当に削除しますか？'))) return;
      const index = items.value.indexOf(item);
      items.value.splice(index, 1);
    };

    const checkUserIds = async () => {
      userId.value = '';

      try {
        isCheckUsers.value = true;
        if (isPermitted && !userIds.value.length) {
          await getAllUsers().then((_users) => {
            userIds.value = _users.filter((user) => !user.deleteDate).map((user) => user.userId);
          });
        }
      } catch (e) {
        console.error(e);
      } finally {
        // ユーザーデータを取得、有効なデータが取得できない場合
        if (!userIds.value.length) {
          isCheckUsers.value = false;
        } else {
          let hasInvalidUser = false;
          // ユーザーデータのチェック
          items.value.forEach((user) => {
            if (userIds.value.includes(user.userId)) user.status = 'valid';
            else {
              user.status = 'invalid';
              if (!hasInvalidUser) hasInvalidUser = true;
            }
          });
          isCheckUsers.value = false;

          // エラー（退会済みまたは存在しないユーザーID指定）があればアラートを表示する
          if (hasInvalidUser)
            alert(
              '通知対象外のユーザーが含まれています。\nアラートアイコンが表示されている行について、退会済みまたは存在しないユーザーIDを指定していないかご確認ください。'
            );
          else {
            alert('ユーザーのチェックが完了しました。\n追加ボタンを押して、特典付与を行うことができます。');
            isErrorUser.value = false;
          }
        }
      }
    };

    watch(
      () => items.value,
      () => {
        if (!items.value.length) {
          dialogTableHeaders.value = [{ text: '', value: 'actions', align: 'end' }];
          isAddGiftDone.value = false;
          failedUserIds.value = [];
        }
      }
    );

    return {
      DisplayDate,
      pageTitle: '特典',
      myRoleSettings,
      gifts,
      headers,
      deleteGift,
      searchWord,
      isDisplayItem,
      displayGifts,
      upload,
      close,
      items,
      dialogTableHeaders,
      active,
      open,
      giftId,
      add,
      deleteUpdateGift,
      download,
      setCsv,
      checkUserIds,
      isCheckUsers,
      isErrorUser,
      isPermitted,
      failedUserIds,
      isAddGiftDone,
      getExpirationTypeText,
      getExpirationPeriodIntervalText,
    };
  },
});
